<template>
  <div class="stack">
    <div class="stack-info">
      <div class="stack-info__content">
        <page-title :hint="true" class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <!--        <plain-button-->
        <!--          v-tooltip="{-->
        <!--            content: $t('title.hint'),-->
        <!--            placement: 'bottom-start',-->
        <!--            container: false,-->
        <!--          }"-->
        <!--          icon="help"-->
        <!--          size="medium"-->
        <!--          color="dim"-->
        <!--          tabindex="-1"-->
        <!--          class="stack-info__title-hint"-->
        <!--        />-->
        <div class="stack-mode"></div>
      </div>
    </div>
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest">
      <base-empty v-if="list.length === 0" title="$t('newDisk')" class="cloud-info__empty">
        <template #link
          ><base-button>
            {{ $t('newDisk') }}
          </base-button></template
        >
      </base-empty>
      <tariffs-table-snapshots />
    </page-block>
  </div>
</template>

<script>
import TariffsTableSnapshots from '../../Main/components/TariffsTableSnapshots';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import newDisk from '@/layouts/Stack/mixins/newDisk';
export default {
  name: 'ViewSnap',
  components: {
    TariffsTableSnapshots,
    BaseEmpty,
  },
  mixins: [storeMixin, newDisk],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isRequest: true,
      diskId: '',
    };
  },
  computed: {
    quotas() {
      return Object.keys(this.$store.state.moduleStack.quotasVolume).length;
    },
    isDisks() {
      if (
        this.$store.state.moduleStack.quotasVolume &&
        this.$store.state.moduleStack.quotasVolume.maxTotalVolumes &&
        this.$store.state.moduleStack.quotasVolume.totalVolumesUsed
      ) {
        return (
          this.$store.state.moduleStack.quotasVolume.maxTotalVolumes -
          this.$store.state.moduleStack.quotasVolume.totalVolumesUsed
        );
      } else return -1;
    },
    aviableSpaceVolume() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
  },
  watch: {
    isDisks(event) {
      // console.log(event);
      return (
        this.$store.state.moduleStack.quotasVolume.maxTotalVolumes -
        this.$store.state.moduleStack.quotasVolume.totalVolumesUsed
      );
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    // this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'volumes').then(data => {
    //   let volumeId = data.volumes
    //     .filter(x => x.status === 'creating' || x.status === 'queued')
    //     .map(x => x.id);
    //   if (volumeId.length > 0) {
    //     volumeId.forEach(x => {
    //       // console.log(x);
    //       this.isDisks;
    //       this.diskId = x;
    //       this.updateDiskStatus(_, x);
    //     });
    //   }
    // });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    // async updateDiskStatus(callback, payload) {
    //   this.timerId = setTimeout(() => {
    //     this.$store
    //       .dispatch('moduleStack/checkDiskStatus', {
    //         disk: this.diskId,
    //         type: 'volumes',
    //         silent: true,
    //       })
    //       .then(async data => {
    //         if (['extending'].includes(data)) {
    //           this.res = data;
    //           await this.updateDiskStatus(callback, payload);
    //         } else {
    //           // console.log(data);
    //           this.timerCnt = 0;
    //           this.isProcessing = false;
    //           clearTimeout(this.timerId);
    //           this.res = data;
    //           return 'success';
    //         }
    //       });
    //   }, 1000 * this.timerCnt);
    // },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Снапшоты",
      "stats": "Статистика",
      "hint": "К облачному серверу можно подключать только сетевые диски. Диск не должен быть подключен к другим серверам. Пока диск загрузочный, его нельзя открепить от сервера без остановки. Локальный диск может быть создан только вместе с облачным сервером и он не может перемещаться между облачными серверами."
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "sure": {
      "confirm": "Создать"
    },
    "newDisk": "Новый диск",
    "quotaDisks": "Вы достигли квоты по количеству сетевых дисков, обратитесь в поддержку для изменения квоты",
    "quotaSpaceDisks": "Вы достигли квоты по объему сетевых дисков, обратитесь в поддержку для изменения квоты",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__title{
      &-hint {
        margin: -0.25rem 0.25rem;
        margin-top: -1rem;
        vertical-align: middle;
      }
    }
    &__content {
      margin-top: 1.5rem;
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }
    &__create {
      display: flex;
      flex-direction row;
      align-content flex-end;
      align-items baseline;
      //flex: 1 1 auto;
      margin-top: 1.5rem;
      max-width: 200px;
      &-btn {
        margin-top: 20px
        margin-right: 0;
        +breakpoint(ms-and-up) {
          margin-top: 40px;
        }
      }
      &-hint {
        margin: -0.25rem 0.25rem;
        vertical-align: middle;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
    //&__create {
    //  flex: 0 0 auto;
    //  margin-top: 1.5rem;
    //  max-width: 160px;
    //
    //  +breakpoint(sm-and-up) {
    //    margin: 0 0 0 auto;
    //  }
    //}
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
